var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page" },
    [
      _c(
        "div",
        [
          _vm._m(0),
          _c("MainButton", {
            staticClass: "confirm-button",
            attrs: {
              title: "Понятно",
              type: "primary",
              size: "big",
              width: "full",
            },
            on: {
              "button-clicked": function ($event) {
                return _vm.$router.push({ name: "terminal-carNumber" })
              },
            },
          }),
        ],
        1
      ),
      _c("TechSupport"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "response" }, [
      _c("div", { staticClass: "response__title" }, [
        _vm._v("Невозможно стать в общую очередь!"),
      ]),
      _c("div", { staticClass: "response__text" }, [
        _vm._v(
          " На терминале отсутствуют квоты по культурам на ближайшие 3 дня. Вы можете повторить попытку позже. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }